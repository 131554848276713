@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';


.product--layout--full {
    .product__body {
        display: grid;
        grid-template-columns: auto 1fr 370px;
        grid-template-rows: max-content auto auto 1fr;
    }
    .product__card--one {
        grid-column: 1 / 3;
        grid-row: 1 / 4;
    }
    .product__gallery {
        grid-column: 1;
        grid-row: 1 / 4;
    }
    .product__header {
        grid-column: 2;
        grid-row: 1;
    }
    .product__main {
        grid-column: 2;
        grid-row: 2;
    }
    .product__info {
        grid-column: 3;
        grid-row: 1 / 5;
    }
    .product__tabs {
        grid-column: 1 / 3;
        grid-row: 4;
    }


    --product-gutter: 30px;


    .product__card {
        @include card;
    }
    .product__card--two {
        display: none;
    }
    .product__gallery {
        $local-padding-start: 40px;

        padding-top: 36px;
        padding-bottom: 48px;
        width: 400px + $local-padding-start;

        @include direction {
            #{$padding-inline-start}: $local-padding-start;
            #{$padding-inline-end}: 0;
        }
    }
    .product__header {
        padding-top: 36px;
        padding-bottom: 16px;

        @include direction {
            #{$padding-inline-start}: 36px;
            #{$padding-inline-end}: 40px;
        }
    }
    .product__main {
        padding-top: 0;
        padding-bottom: 48px;

        @include direction {
            #{$padding-inline-start}: 36px;
            #{$padding-inline-end}: 40px;
        }
    }
    .product__info {
        @include direction {
            #{$margin-inline-start}: var(--product-gutter);
        }
    }
    .product__shop-features,
    .product__tabs {
        margin-top: var(--product-gutter);
    }







    .product__subtitle {
        padding-top: 8px;
    }
    .product__gallery-intro {
        display: flex;
    }
    .product__excerpt {
        font-size: 15px;
    }
    .product__features {
        margin: 20px 0 0;
        font-size: 14px;
        border-radius: 3px;

        $local-gutter: 12px;
        $local-columns: 1;

        ul {
            list-style: none;
            padding: 0;
            color: map_get($card-scheme, opposite-alt);
            margin: 0 -($local-gutter / 2);
            display: flex;
            flex-wrap: wrap;
        }
        li {
            padding-top: 3px;
            padding-bottom: 2px;
            position: relative;
            margin: 0 ($local-gutter / 2);
            width: calc(100% / #{$local-columns} - #{$local-gutter});

            @include direction {
                #{$padding-inline-start}: 11px;
                #{$padding-inline-end}: 0;
            }

            &:before {
                $local-size: 3px;

                position: absolute;
                display: block;
                content: '';
                width: $local-size;
                height: $local-size;
                border-radius: $local-size / 2;
                background: currentColor;
                top: 12px;
                opacity: .85;

                @include direction {
                    #{$inset-inline-start}: 0;
                }
            }
        }
    }
    .product__features-title {
        font-weight: $font-weight-medium;
        padding-bottom: 12px;
    }
    .product__features-link {
        $local-expand: 2px;

        margin: 20px (-$local-expand) 0;
        padding: 20px $local-expand 0;
        font-size: 14px;
        border-top: 1px solid map_get($card-scheme, divider);

        a:hover {
            text-decoration: underline;
        }
    }


    @include media-breakpoint-up(xl) {
        // styles
        .product__subtitle {
            flex-direction: column;
            align-items: flex-start;
        }
        .product__fit {
            margin-bottom: 11px;
            order: -1;

            @include direction {
                #{$margin-inline-start}: -4px;
            }
        }
    }
    @include media-breakpoint-up(md) {
        // styles
        .product__info-card {
            @include card;
        }
    }
    @include media-breakpoint-only(md) {
        // styles
        .product__gallery {
            @include card;
        }
        .product__actions-item--addtocart .btn {
            @include direction {
                #{$padding-inline-start}: 16px;
                #{$padding-inline-end}: 16px;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        // styles
        .product__subtitle {
            flex-direction: column;
            align-items: flex-start;
        }
        .product__fit {
            margin-bottom: 11px;
            order: -1;

            @include direction {
                #{$margin-inline-start}: -4px;
            }
        }
    }
    @include media-breakpoint-only(md) {
        // actions
        $local-size: 40px;
        $local-icon-size: 16px;
        $local-padding-right: 28px;

        .product__title {
            @include direction {
                #{$padding-inline-end}: (52px + 12px + 16px);
            }
        }
        .product__actions-item--wishlist,
        .product__actions-item--compare {

            position: absolute;
            top: (24px + 16px - ($local-size) / 2 - 1px);
            width: $local-size;
            height: $local-size;
            border-radius: $local-size / 2;
            padding: 0;
            margin: 0;

            @include direction {
                #{$inset-inline-end}: (28px - ($local-size - 16px) / 2);
            }

            span {
                display: none;
            }
            svg {
                top: 0;
                display: block;
                margin: 0;
            }
        }
        .product__actions-item--compare {
            @include direction {
                #{$inset-inline-end}: (28px - ($local-size - 16px) / 2 + $local-size - 4px);
            }
        }
    }


    @include media-breakpoint-down(xl) {
        // variables
        --product-gutter: 24px;

        // grid
        .product__body {
            grid-template-columns: auto 1fr (312px + 24px);
        }

        // styles
        .product__gallery {
            $local-padding-start: 28px;

            padding-top: 24px;
            padding-bottom: 36px;
            width: 320px + $local-padding-start;

            @include direction {
                #{$padding-inline-start}: $local-padding-start;
                #{$padding-inline-end}: 0;
            }
        }
        .product__header {
            padding-top: 24px;
            padding-bottom: 16px;

            @include direction {
                #{$padding-inline-start}: 28px;
                #{$padding-inline-end}: 28px;
            }
        }
        .product__main {
            padding-top: 0;
            padding-bottom: 36px;

            @include direction {
                #{$padding-inline-start}: 28px;
                #{$padding-inline-end}: 28px;
            }
        }
        .product__info-body,
        .product__form,
        .product__actions,
        .product__tags-and-share-links {
            $local-padding-x: 20px;

            @include direction {
                #{$padding-inline-start}: $local-padding-x;
                #{$padding-inline-end}: $local-padding-x;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        // grid
        .product__body {
            grid-template-columns: auto 1fr (312px + 24px);
            grid-template-rows: max-content auto auto 1fr;
        }
        .product__card {
            grid-column: 1 / 3;
            grid-row: 1 / 4;
        }
        .product__gallery {
            grid-column: 1 / 3;
            grid-row: 3;
        }
        .product__header {
            grid-column: 1 / 3;
            grid-row: 1;
        }
        .product__main {
            grid-column: 1 / 3;
            grid-row: 2;
        }
        .product__info {
            grid-column: 3;
            grid-row: 1 / 4;
        }
        .product__tabs {
            grid-column: 1 / 4;
            grid-row: 4;
        }

        // styles
        .product__features {
            display: none;
        }
        .product__main {
            padding-bottom: 16px;
        }
        .product__excerpt {
            border-bottom: 1px solid map_get($card-scheme, divider);
            padding-bottom: 14px;
        }
        .product__gallery {
            --product-gallery-image-max-width: 380px;
            padding-top: 0;
            width: 100%;

            @include direction {
                #{$padding-inline-end}: 28px;
            }
        }
        .product__header {
            padding-bottom: 10px;
        }
        .product__info {
            display: flex;
        }
        .product__shop-features {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        // variables
        --product-gutter: 16px;

        // grid
        .product__body {
            grid-template-columns: auto 1fr;
            grid-template-rows: max-content auto auto 1fr;
        }
        .product__card {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
        }
        .product__header {
            grid-column: 1 / 3;
            grid-row: 1;
        }
        .product__main {
            grid-column: 1 / 3;
            grid-row: 2;
        }
        .product__gallery {
            grid-column: 1;
            grid-row: 3;
        }
        .product__info {
            grid-column: 2;
            grid-row: 3;
        }

        // styles
        .product__gallery {
            margin-top: var(--product-gutter);
            width: 385px;
            padding-top: 24px;
            padding-bottom: 28px;

            @include direction {
                #{$padding-inline-start}: 24px;
                #{$padding-inline-end}: 24px;
            }
        }
        .product__info {
            @include direction {
                #{$margin-inline-start}: var(--product-gutter);
            }
        }
        .product__excerpt {
            border: none;
            padding-bottom: 8px;
        }
        .product__info-card {
            position: static;
            margin-top: var(--product-gutter);
            padding-top: 24px;
            padding-bottom: 28px;

            @include direction {
                #{$padding-inline-start}: 24px;
                #{$padding-inline-end}: 24px;
            }
        }
        .product__info-body {
            padding-top: 0;
            padding-bottom: 16px;

            @include direction {
                #{$padding-inline-start}: 0px;
                #{$padding-inline-end}: 0px;
            }
        }
        .product__prices-stock {
            padding-top: 0;
            padding-bottom: 10px;

            @include direction {
                #{$padding-inline-start}: 0px;
                #{$padding-inline-end}: 0px;
            }
        }
        .product__price {
            font-size: 26px;
        }
        .product__price--old {
            font-size: 16px;
        }
        .product__meta {
            border-top: 1px solid map_get($card-scheme, divider);
            border-bottom: 1px solid map_get($card-scheme, divider);
            padding: 10px 0;
            margin: 8px 0;

            th,
            td {
                padding-top: 1px;
                padding-bottom: 1px;
            }
            tr + tr > * {
                border: none;
            }
        }
        .product__form {
            background: transparent;
            border: none;
            margin-bottom: 16px;
            padding-top: 0;
            padding-bottom: 0;

            @include direction {
                #{$padding-inline-start}: 0;
                #{$padding-inline-end}: 0;
            }
        }
        .product__actions {
            padding-top: 0;
            padding-bottom: 0;

            @include direction {
                #{$padding-inline-start}: 0;
                #{$padding-inline-end}: 0;
            }
        }
        .product__tags-and-share-links {
            border: none;
            padding-top: 20px;
            padding-bottom: 0;

            @include direction {
                #{$padding-inline-start}: 0;
                #{$padding-inline-end}: 0;
            }
        }
        .product-gallery[data-layout="product-full"] .product-gallery__zoom {
            top: -9px - 6px;

            @include direction {
                #{$inset-inline-end}: -9px - 6px;
            }
        }
    }


    @include media-breakpoint-only(sm) {
        // styles
        .product__meta {
            $local-gutter: 20px;

            table,
            th,
            td {
                display: block;
            }
            tbody {
                display: flex;
                flex-wrap: wrap;
            }
            tr,
            th {
                width: calc(50% - #{$local-gutter / 2});
            }
            tr {
                display: flex;

                &:nth-child(2n) {
                    @include direction {
                        #{$margin-inline-start}: $local-gutter;
                    }
                }
            }
        }
    }


    @include media-breakpoint-down(sm) {
        // grid
        .product__body {
            grid-template-columns: 100%;
            grid-template-rows: auto;
        }
        .product__header {
            grid-column: 1;
            grid-row: 1;
        }
        .product__gallery {
            grid-column: 1;
            grid-row: 2;
        }
        .product__card--two {
            grid-column: 1;
            grid-row: 3 / 5;
        }
        .product__main {
            grid-column: 1;
            grid-row: 3;
        }
        .product__info {
            grid-column: 1;
            grid-row: 4;
        }
        .product__tabs {
            grid-column: 1;
            grid-row: 5;
        }

        // styles
        .product__card--one {
            display: none;
        }
        .product__card--two {
            display: block;
        }
        .product__header {
            @include card;

            padding-top: 24px;
            padding-bottom: 22px;

            @include direction {
                #{$padding-inline-start}: 24px;
                #{$padding-inline-end}: 24px;
            }
        }
        .product__title {
            font-size: 22px;
            line-height: 28px;
        }
        .product__subtitle {
            padding-top: 8px;
        }
        .product__body {
            width: 100%;
        }
        .product__gallery {
            @include card;

            --product-gallery-image-max-width: 360px;
            width: 100%;
        }
        .product__main,
        .product__card--two {
            margin-top: var(--product-gutter);
        }
        .product__main {
            padding-top: 24px;
            padding-bottom: 18px;

            @include direction {
                #{$padding-inline-start}: 24px;
                #{$padding-inline-end}: 24px;
            }
        }
        .product__excerpt {
            padding-bottom: 0;
        }
        .product__info {
            width: 100%;

            @include direction {
                #{$margin-inline-start}: 0;
            }
        }
        .product__info-card {
            margin-top: 0;
            padding-top: 0;
        }
        .product__actions {
            margin-top: -12px;
            margin-bottom: -6px;
        }
        .product__actions-item--quantity,
        .product__actions-item--addtocart {
            margin-top: 10px;
        }
        .product__actions-item--addtocart {
            flex-grow: 0;
        }
        .product__actions-divider {
            height: 10px;
        }
        .product__actions-item--wishlist,
        .product__actions-item--compare {
            width: auto;
            margin-top: 2px;
            margin-bottom: -2px;
        }
    }
}
